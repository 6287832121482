export const initialState = {
  loading: false,
  conversationList: localStorage.getItem('sp_chat_history')? JSON.parse(localStorage.getItem('sp_chat_history')) : [],
  error: null,
  hasConversation: !!localStorage.getItem('sp_chat_history'),
  viewMode: 'bubble',
  feedBackLoader: false
}

export const reducer = (state, action) => {
  switch (action.type) {
    case 'GET_AI_RESPONSE':
      return {
        ...state,
        loading: true,
        hasConversation: true,
        conversationList: [...state.conversationList , action.payload],
        error: null
      }
    case 'GET_AI_RESPONSE_SUCCESS':
      const updatedChatList = [...state.conversationList , { ...action.payload, feedback: '' }];
      localStorage.setItem("sp_chat_history", JSON.stringify(updatedChatList));
      return {
        ...state,
        loading: false,
        conversationList: updatedChatList,
        error: action.payload.hasError
      }
    case 'GET_AI_RESPONSE_FAILURE':
      return {
        ...state,
        loading: false,
        error: 'Something went wrong...'
      }
    case 'RESET_CONVERSATION':
      localStorage.removeItem('sp_chat_history');
      return {
        ...state,
        hasConversation: false,
        conversationList: []
      }
    case 'SET_FEEDBACK':
      const updatedList = state.conversationList.map(data => {
        if(data.messageId === action.payload.messageId) {
          return { ...data, feedback: action.payload.feedback }
        }
        return data;
      })
      localStorage.setItem("sp_chat_history", JSON.stringify(updatedList));
      return {
        ...state,
        feedBackLoader: true,
        CurrentId: action.payload.messageId,
        conversationList: updatedList,
      }
    case 'SET_FEEDBACK_SUCCESS':
      return {
        ...state,
        feedBackLoader: false,
        CurrentId: null
      }
    case 'SET_FEEDBACK_FAILURE':
      return {
        ...state,
        feedBackLoader: false,
        CurrentId: null
      }
    case 'SET_MODE':
      return {
        ...state,
        viewMode: action.payload
      }
    case 'SET_RETRY_LOADER':
      return {
        ...state,
        loading: true,
      }
    default: return state;
  }
}