import styled, { css } from 'styled-components';
import { createGlobalStyle } from 'styled-components'

const reset = css`
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
  }
`;

export const GlobalStyle = createGlobalStyle`
    ${reset}
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  body {
    font-family: Roboto,serif;
  }
  .sp-primary-button {
      color: #FFF;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      padding: 8px 30px;
      border-radius: 12px;
      background: #5BA700;
      cursor: pointer;
      border: 1px solid #5BA700;
      &:hover {
          opacity: 0.95;
          box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.10);
      }
  }
  textarea {
      font-family: Roboto,serif;
  }
`

export const IframeStyleContainer = styled.div`
    .sp-iframe-container {
        position: fixed;
        bottom: 40px;
        right: 40px;
        animation-name: height-increase;
        animation-duration: .5s;
        z-index: 999999;
        max-height: 453px;
        max-width: 370px;
        @keyframes height-increase {
            0% { opacity: 0; height: 300px; width: 300px  }
            25% { opacity: 0 }
            50% { opacity: 0 }
            75% { opacity: 1 }
            100%{ opacity: 1; height: 458px; width: 370px }
        }
        iframe {
            border: 0;
            box-shadow: 0 0 24px 0 rgba(0,0,0,0.10);
            border-radius: 24px;
        }
        .sp-chat-header {
            position: absolute;
            width: 118px;
            height: 59px;
            top: -59px;
            left: 125px;
            border-radius: 100px 100px 0px 0px;
            background: #F7F7F7;
            border: 1px solid #F7F7F7;
            svg {
                width: 100%;
                margin-top: 15px;
            }
            &.bordered {
                border: 1px solid #5BA700;
                border-bottom: none;   
            }
        }
        &.mobile {
            max-width: 290px;
            bottom: 10px;
            right: 10px;
        }
    }
`;


export const ChatWidgetContainer = styled.div`
  .sp-widget-container {
    //width: 370px;
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.10);
    border-radius: 24px;
    position: relative;
    height: 453px;
    &.human {
        border: 1px solid #5BA700;
        .sp-user-email {
            position: relative;
            input {
                padding-right: 30px;
            }
            svg {
                position: absolute;
                right: 10px;
                top: 20px;
            }
        }
        .sp-user-description {
            position: relative;
            .sp-form-submit {
                position: absolute;
                right: 10px;
                bottom: 10px;
                fill: #B5B5B5;
                padding: 2px;
                cursor: pointer;
                background: #F7F7F7;
                border-radius: 5px;
                &.has-not-valid {
                    cursor: not-allowed;
                }
            }
            .sp-back-to-chat {
                padding: 2px;
                cursor: pointer;
                background: #F7F7F7;
                border-radius: 5px;
            }
            &.full-height {
                textarea {
                    height: 198px!important;
                }
            }
        }
    }
    .sp-chat-header-container {
      display: flex;
      justify-content: space-between;
      gap: 8px;
      border-radius: 24px 24px 0px 0px;
      background: #F7F7F7;
      padding: 17px;
      height: 103px;
      h1 {
        color: #0B1242;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .sp-kb-button {
        border-radius: 100px;
        border: 1px solid #D2D2D2;
        height: 26px;
        width: 26px;
        padding: 5px;
        box-sizing: border-box;
        background-color: #fff;
        cursor: pointer;
        position: relative;
        svg {
          width: 100%;
          height: 100%;
        }
          .tooltip-text {
              visibility: hidden;
              width: 130px;
              background-color: black;
              color: #fff;
              text-align: center;
              border-radius: 4px;
              padding: 6px;
              position: absolute;
              font-size: 12px;
              z-index: 1;
              top: -8px;
              left: 140%;
              &::after {
                  content: "";
                  position: absolute;
                  top: 50%;
                  right: 100%;
                  margin-top: -5px;
                  border-width: 5px;
                  border-style: solid;
                  border-color: transparent black transparent transparent;
              }

          }
      }
        .sp-kb-button:hover .tooltip-text {
            visibility: visible;
        }
        .sp-widget-title {
            text-align: center;
            margin-left: 32px;
            & > span {
                color: #000;
                font-size: 12px;
            }
            h1 {
                margin-top: 8px;
            }
        }
        .sp-action-buttons {
            display: flex;
            .sp-reset-button, .sp-close-icon {
                width: 24px;
                height: 24px;
                background-color: #EEE;
                padding: 4px;
                box-sizing: border-box;
                border-radius: 50%;
                cursor: pointer;
                position: relative;
                &:hover {
                    box-shadow: 0px 1px 3px 2px #d7d7d7;
                }
            }
            .sp-reset-button {
                cursor: not-allowed;
                &:hover {
                    box-shadow: unset;
                }
                &.active {
                    background: #DEEECB;
                    cursor: pointer;
                    &:hover {
                        box-shadow: 0px 1px 3px 2px #d7d7d7;
                    }
                }
                &.hidden {
                    visibility: hidden;
                }
                margin-right: 8px;
                position: relative;
                .tooltip-text {
                    visibility: hidden;
                    width: 120px;
                    background-color: black;
                    color: #fff;
                    text-align: center;
                    border-radius: 4px;
                    padding: 6px;
                    position: absolute;
                    font-size: 12px;
                    z-index: 1;
                    top: 0;
                    right: 135%;
                    &::after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 100%;
                        margin-top: -5px;
                        border-width: 5px;
                        border-style: solid;
                        border-color: transparent transparent transparent black;
                    }

                }
            }
            .sp-reset-button:hover .tooltip-text {
                visibility: visible;
            }
            .sp-close-icon {
                padding: 5px;
            }
        }
    }
      &.chat {
          .sp-chat-header-container {
              border: 1px solid #F7F7F7;
              border-bottom: none;
          }
          .sp-input-container {
              border: 1px solid #fff;
              border-top: none;
          }
      }
      &.mobile {
          * {
              font-size: 12px;
          }
          .sp-chat-header-container {
              gap: unset;
              .sp-widget-title {
                  margin-left: 25px;
              }
          }
          .sp-chat-widget-container .sp-chat-window .sp-system-chat .sp-chat-content p {
              width: 68%;
          }
          .sp-user-description.full-height textarea {
              height: 165px!important;
          }
      }
  }
  .sp-chat-widget-container {
    background-color: #fff;
    min-height: 270px;
    height: 270px;
    overflow-y: scroll;
    padding: 0 16px 0;
    .sp-chat-time {
      color: #A5A5A5;
      font-size: 12px;
      font-weight: 400;
      text-align: center;
      position: sticky;
      padding-bottom: 8px;
      padding-top: 6px;
      top: 0;
      background: #fff;
      z-index: 2;
    }
    .sp-chat-window {
      height: auto;
      min-height: 242px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: end;
      .sp-user-chat, .sp-system-chat {
        .sp-chat-content {
          display: flex;
          align-items: center;
          margin-top: 8px;
          p {
              border-radius: 12px 12px 12px 4px;
              border: 1px solid #EFEFEF;
              background: #F7F7F7;
              padding: 12px;
              font-size: 12px;
              max-width: 73%;
              white-space: pre-line;
              a {
                  text-decoration: underline;
                  color: #4D98EA;
              }
          }
          & > img, & > svg {
              height: 36px;
              width: 36px;
              border-radius: 50%;
          }
          .loader {
              width: 40px;
              aspect-ratio: 4;
              margin-top: 2px;
              --_g: no-repeat radial-gradient(circle closest-side, #D9D9D9 90%, #0000);
              background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
              background-size: calc(100% / 4) 100%;
              animation: loading 1s infinite linear;
          }
            @keyframes loading {
                33%{background-size:calc(100%/4) 50% ,calc(100%/4) 100%,calc(100%/4) 100%}
                50%{background-size:calc(100%/4) 100%,calc(100%/4) 50%  ,calc(100%/4) 100%}
                66%{background-size:calc(100%/4) 100%,calc(100%/4) 100%,calc(100%/4) 50%  }
            }
        }
      }
      .sp-system-chat {
        .sp-chat-content {
          p {
              margin-left: 8px;
          }
        }
        & + .sp-system-chat {
            .sp-chat-content svg {
                visibility: hidden;
            }
        }
      }
      .sp-user-chat {
        .sp-chat-content {
          justify-content: flex-end;
          p {
            margin-right: 8px;
            border-radius: 12px 12px 4px 12px;
            border: 1px solid #A8D375;
            background: #E3F7CB;
          }
          p {
            margin-right: 8px;
          }
        }
          & + .sp-user-chat {
              .sp-chat-content svg {
                  visibility: hidden;
              }
          }
      }
        .sp-response-feedback {
            margin: 8px 0 4px 43px;
            display: flex;
            .action-icon {
                position: relative;
                display: inline-block;
                margin-right: 8px;
                .tooltip-text {
                    visibility: hidden;
                    width: 120px;
                    background-color: black;
                    color: #fff;
                    text-align: center;
                    border-radius: 4px;
                    padding: 10px;
                    position: absolute;
                    z-index: 1;
                    bottom: 120%;
                    left: 50%;
                    margin-left: -60px;
                    font-size: 14px;
                    &::after {
                        content: "";
                        position: absolute;
                        top: 100%;
                        left: 50%;
                        margin-left: -5px;
                        border-width: 5px;
                        border-style: solid;
                        border-color: black transparent transparent transparent;
                    }

                }
                &.active {
                    svg {
                        background: #E3F7CB;
                    }
                } 
            }
            .action-icon:hover .tooltip-text {
                visibility: visible;
            }
            svg {
                border-radius: 8px;
                cursor: pointer;
                &:hover {
                    background: #E3F7CB;
                }
            }
            &.has-retry {
                .action-icon:not(:last-child) {
                    svg {
                        filter: grayscale();
                    }
                    pointer-events: none;
                }
            }
        }
    }
  }
    .sp-input-container {
        position: relative;
        background: #fff;
        border-radius: 0 0 24px 24px;
        height: 78px;
        padding: 16px;
        .sp-chat-submit-button {
            padding: 2px;
            position: absolute;
            right: 26px;
            top: 27px;
            cursor: pointer;
            &.disabled {
                svg, svg path {
                    fill: #E2E2E2!important;
                    cursor: not-allowed;
                }
            }
        }
        .sp-input {
            border-radius: 16px;
            background: #FFF;
            border: 1px solid #5BA700;
            width: 100%;
            height: 48px;
            padding: 16px 40px 16px 24px;
            box-sizing: border-box;
            outline: #F7F7F7;
            &:focus-visible {
                outline: #F7F7F7;
            }
            &::placeholder {
                color: #B5B5B5;
                opacity: 1; /* Firefox */
            }
            &::-ms-input-placeholder { /* Edge 12 -18 */
                color: #B5B5B5;
            }
            &:disabled {
                border: 1px solid #E2E2E2; 
                cursor: not-allowed;
            }
        }
    }
  .sp-human-container {
    padding: 16px;
    background: #ECF1E7;
    border-radius: 0 0 24px 24px;
    height: 348px;
    p {
      margin-bottom: 8px;
      font-size: 14px;
    }
    input, textarea {
      border-radius: 16px;
      border: 1px solid #5BA700;
      background: #F7F7F7;
      width: 100%;
      box-sizing: border-box;
      outline: #F7F7F7;
        &:focus-visible {
            outline: #F7F7F7;   
        }
    }
    input {
      padding: 14px 24px;
      margin-bottom: 8px;
      margin-top: 10px;
    }
    textarea {
      padding: 16px 24px 0;
      height: 130px;
      resize: none;
      border-bottom: none;
      border-radius: 16px 16px 0 0;
    } 
    .sp-user-form-submit {
        display: flex;
        justify-content: space-between;
        padding: 0px 10px 10px 10px;
        background: #F7F7F7;
        border: 1px solid #5BA700;
        border-radius: 0 0 16px 16px;
        border-top: none;
    }
    }
  }
    .sp-chat-header, .sp-ask-email {
        position: absolute;
        width: 118px;
        height: 59px;
        top: -59px;
        left: 125px;
        border-radius: 100px 100px 0px 0px;
        background: #F7F7F7;
        svg {
            width: 100%;
            margin-top: 15px;
        }
    }
`;


export const ChatBubble = styled.div`
    .sp-chat-bubble {
        display: flex;
        vertical-align: center;
        align-items: center;
        background: #A8D375;
        box-shadow: 0px 2px 10px 0px rgba(153, 188, 111, 0.75);
        width: 50px;
        height: 50px;
        padding: 4px;
        justify-content: center;
        position: fixed;
        bottom: 40px;
        right: 40px;
        border-radius: 50%;
        cursor: pointer;
        z-index: 999999;
        box-sizing: border-box;
    }
`;