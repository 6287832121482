import ChatWindowContainer from "./ChatWindowContainer";
import AskRealPersonContainer from "./AskRealPersonContainer";

const ChatWindowContent = ({ viewType, setViewType, state, dispatch }) => {

  if (viewType === 'chat')
    return (
      <ChatWindowContainer
        state={state}
        dispatch={dispatch}
        setViewType={setViewType}
      />
    );
  return <AskRealPersonContainer setViewType={setViewType} />
}

export default ChatWindowContent