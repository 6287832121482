import { useState } from "preact/hooks";
import debounce from "lodash.debounce";
import { Translate } from "../helpers/utility";
import i18n from "../helpers/i18n";
import { askRealPersonAPI } from "../helpers/constants";

const BackToChatIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="mingcute:chat-1-line" clip-path="url(#clip0_592_56777)">
      <g id="Group">
        <path id="Vector" d="M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22H4C3.46957 22 2.96086 21.7893 2.58579 21.4142C2.21071 21.0391 2 20.5304 2 20V12C2 6.477 6.477 2 12 2ZM12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12V20H12C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4ZM12 14C12.2549 14.0003 12.5 14.0979 12.6854 14.2728C12.8707 14.4478 12.9822 14.687 12.9972 14.9414C13.0121 15.1958 12.9293 15.4464 12.7657 15.6418C12.6021 15.8373 12.3701 15.9629 12.117 15.993L12 16H9C8.74512 15.9997 8.49997 15.9021 8.31463 15.7272C8.1293 15.5522 8.01776 15.313 8.00283 15.0586C7.98789 14.8042 8.07067 14.5536 8.23426 14.3582C8.39785 14.1627 8.6299 14.0371 8.883 14.007L9 14H12ZM15 10C15.2652 10 15.5196 10.1054 15.7071 10.2929C15.8946 10.4804 16 10.7348 16 11C16 11.2652 15.8946 11.5196 15.7071 11.7071C15.5196 11.8946 15.2652 12 15 12H9C8.73478 12 8.48043 11.8946 8.29289 11.7071C8.10536 11.5196 8 11.2652 8 11C8 10.7348 8.10536 10.4804 8.29289 10.2929C8.48043 10.1054 8.73478 10 9 10H15Z" fill="#5BA700"/>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_592_56777">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

const AskRealPersonContainer = ({ setViewType }) => {
  const [emailValidStatus, setEmailValid] = useState(localStorage.getItem('sp_chatbot_user_email') || false),
    [emailValue, setEmail] = useState(''),
    [userMessage, setUserMessage] = useState(''),
    [submissionState, setSubmissionState] = useState(false);
  const userEmail = localStorage.getItem('sp_chatbot_user_email') || false;
  const emailInString = /^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const updateEmail = debounce((value) => {
    setEmailValid(!!value?.match(emailInString)?.length);
    setEmail(value)
  }, 200);

  const handleEmailChange = ({ target: { value }}) => {
    if(value.trim() || value === '') {
      updateEmail(value);
    }
  }

  const updateUserMessage = debounce((value) => {
    setUserMessage(value);
  }, 500);

  const handleMessageChange = ({ target: { value }}) => {
    if(value.trim() || value === '') {
      updateUserMessage(value)
    }
  }

  const backToChat = () => {
    setViewType('chat');
  }

  const submitResponse = () => {
    if(userMessage && emailValidStatus){
      fetch(askRealPersonAPI, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ query: userMessage, email: userEmail || emailValue })
      }).then(r => setSubmissionState(true))
     }
  }
  return(
    <div class={'sp-human-container'}>
      {!submissionState ?
        <>
          <p><Translate>chatemail_scrn_first_label</Translate></p>
          <p><Translate>chatemail_scrn_second_label</Translate></p>
          {userEmail ? '' : (
            <div class={'sp-user-email'}>
              {emailValidStatus ?
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M16.5675 6.0144L9.18254 13.3848L7.22247 11.4279C6.48523 10.6558 5.26583 10.5587 4.40145 11.2151C3.92423 11.6012 3.64454 12.1498 3.61328 12.7393C3.58202 13.3288 3.80212 13.904 4.21896 14.322L7.95042 18.0609C8.27781 18.3918 8.727 18.5793 9.19566 18.5793C9.66432 18.5793 10.1135 18.3918 10.4432 18.0587L19.5306 8.96375C19.949 8.53825 20.165 7.96117 20.1293 7.37157C20.0935 6.78197 19.8094 6.2352 19.3474 5.86709L19.3336 5.85635C18.4947 5.21925 17.3213 5.29194 16.5675 6.0144ZM18.3771 7.01701L18.4177 7.04464L18.4126 7.04019C18.5423 7.14347 18.622 7.29689 18.632 7.46231C18.642 7.62774 18.5814 7.78965 18.4652 7.90783L9.37949 17.001C9.32902 17.052 9.26376 17.0793 9.19566 17.0793C9.12756 17.0793 9.0623 17.052 9.0144 17.0036L5.2809 13.2626C5.16416 13.1455 5.1024 12.9842 5.11118 12.8188C5.11995 12.6534 5.19842 12.4994 5.32713 12.3952C5.56535 12.2147 5.92752 12.2436 6.15018 12.4767L8.65261 14.9756C8.94536 15.2679 9.41954 15.268 9.71236 14.9757L17.5724 7.13073C17.7928 6.90018 18.1249 6.86043 18.3771 7.01701Z"
                        fill="#5BA700"/>
                </svg> : ''}
              <input type={'text'} placeholder={i18n.t('chatbot_email_address')} onChange={handleEmailChange}/>
            </div>)}
          <div class={`sp-user-description ${userEmail ? 'full-height' : ''}`}>
            <textarea placeholder={i18n.t('chatemail_placeholder_msg')} value={userMessage} onChange={handleMessageChange}/>
            <div class={'sp-user-form-submit'}>
              <div class={`sp-form-submit ${userMessage && emailValidStatus ? '' : 'has-not-valid' }`}>
                <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={submitResponse}>
                  <path id="Vector" d="M22.2698 8.39235L22.2623 8.38907L2.24297 0.0856348C2.07458 0.0151574 1.89136 -0.0124829 1.70967 0.00518625C1.52799 0.0228554 1.35352 0.0852831 1.20188 0.186885C1.04165 0.29187 0.910047 0.435042 0.818895 0.603517C0.727742 0.771993 0.679906 0.960487 0.679688 1.15204L0.679688 6.46251C0.679777 6.72438 0.771215 6.97801 0.938243 7.17969C1.10527 7.38138 1.33742 7.51847 1.59469 7.56735L12.5133 9.58626C12.5562 9.59439 12.5949 9.61725 12.6228 9.65088C12.6506 9.68451 12.6659 9.72681 12.6659 9.77048C12.6659 9.81415 12.6506 9.85645 12.6228 9.89008C12.5949 9.92371 12.5562 9.94656 12.5133 9.9547L1.59516 11.9736C1.33796 12.0224 1.10582 12.1593 0.938722 12.3608C0.77162 12.5623 0.680016 12.8157 0.679688 13.0775L0.679688 18.3889C0.679563 18.5718 0.724854 18.7519 0.811494 18.913C0.898135 19.0741 1.02342 19.2112 1.17609 19.3119C1.35975 19.4339 1.57528 19.4991 1.79578 19.4994C1.94907 19.4993 2.10079 19.4685 2.24203 19.4089L22.2609 11.1528L22.2698 11.1486C22.5393 11.0328 22.7689 10.8405 22.9303 10.5956C23.0916 10.3506 23.1776 10.0638 23.1776 9.77048C23.1776 9.47718 23.0916 9.19031 22.9303 8.94537C22.7689 8.70043 22.5393 8.50816 22.2698 8.39235Z" fill={(userMessage && emailValidStatus) ? '#5BA700': '#B5B5B5'} />
                </svg>
              </div>
              <div class={'sp-back-to-chat'} onClick={backToChat}>
                <BackToChatIcon />
              </div>
            </div>
          </div>
        </> :
        <>
          <p><Translate>chatemail_scrn_third_label</Translate></p>
          <p><Translate>chatemail_scrn_fourth_label</Translate></p>
          <button className={'sp-primary-button'} onClick={backToChat}>
            <Translate>chatbot_back_to_chat</Translate>
          </button>
        </>
      }
    </div>
  );
}
export default AskRealPersonContainer;